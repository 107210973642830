<template>
  <footer
    class="w-screen flex flex-col justify-center items-center overflow-x-hidden mt-8 gap-4 sm:mt-0 bg-[#F8F7F4] pt-4"
  >
    <div class="flex gap-6">
      <img
        src="../assets/images/linkedin_icon.webp"
        alt="Linkedin Icon"
        class="w-6 object-contain cursor-pointer sm:w-8"
        @click="goToLinkedin"
      />
      <img
        src="../assets/images/facebook_icon.webp"
        alt="Facebook Icon"
        class="w-6 object-contain cursor-pointer sm:w-8"
        @click="goToFacebook"
      />
      <img
        src="../assets/images/instagram_icon.webp"
        alt="Instagram Icon"
        class="w-6 object-contain cursor-pointer sm:w-8"
        @click="goToInstagram"
      />
      <a
        href="mailto:sales@oneorder.net"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="../assets/images/email_icon.svg"
          alt="Instagram Icon"
          class="w-6 object-contain cursor-pointer sm:w-8"
        />
      </a>
    </div>
    <div
      class="flex w-screen bg-[#5C0000] text-white font-bold text-[8px] justify-center py-4 sm:text-base sm:py-6"
    >
      {{ $t("footer.copyRight") }}
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  methods: {
    goToLinkedin() {
      window.open("https://www.linkedin.com/company/oneorder-net", "_blank");
    },
    goToFacebook() {
      window.open("https://www.facebook.com/oneorder.egypt", "_blank");
    },
    goToInstagram() {
      window.open("https://www.instagram.com/oneorder.egypt", "_blank");
    },
  },
};
</script>

import en from "./en";
import ar from "./ar";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en,
  ar,
};

const i18n = new VueI18n({
  locale: "ar",
  fallbackLocale: "en",
  messages,
});

export default i18n;

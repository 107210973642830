export const SUPPORTED_LOCALES = [
  {
    code: "en",
    base: "",
    name: "English",
  },
  {
    code: "ar",
    base: "/ar",
    name: "العربية",
  },
];

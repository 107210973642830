<template>
  <div
    class="locale-changer rtl:mb-2.5 rtl:md:mb-3 mt-1"
    :style="{ fontFamily: fontUsed }"
  >
    <button
      class="text-secondary text-lg md:text-3xl relative font-bold"
      :class="{ 'top-1': $i18n.locale === 'ar' }"
      @click="changeLocale"
    >
      {{ languages[$i18n.locale] }}
    </button>
  </div>
</template>

<script>
export default {
  name: "LocaleChanger",
  data() {
    return {
      languages: {
        en: "العربية",
        ar: "English",
      },
    };
  },
  computed: {
    fontUsed() {
      return this.$i18n.locale === "en"
        ? "'Tajawal', sans-serif"
        : "'Montserrat', sans-serif";
    },
  },
  methods: {
    changeLocale() {
      this.$store.dispatch(
        "locale/changeLocale",
        this.$i18n.locale === "en" ? "ar" : "en"
      );
      let newLocale = this.$i18n.locale === "en" ? "ar" : "en";
      this.$router.push(`/${newLocale === "en" ? "" : newLocale}`);
      this.$i18n.locale = newLocale;
    },
  },
};
</script>

<style scoped></style>

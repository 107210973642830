<template>
  <div class="w-screen flex flex-col justify-center items-start p-10 gap-6">
    <div class="flex w-full justify-center">
      <h1 class="font-bold text-3xl text-primary">OneOrder Driver Privacy Policy</h1>
    </div>
    <section>
  This privacy policy explains how Drivers App (“we”, “us”, “our”) collects, uses, and protects your personal information when you use our mobile application (“the app”). By using the app, you agree to this privacy policy and consent to our data practices.
    </section>
    <section class="flex flex-col gap-2">
      <h2 class="text-primary font-bold text-xl">What information do we collect?</h2>
      We collect the following types of information from you when you use the app:
      Location information: We use your device’s location services to track your location while you are using the app. We use this information to provide you with the app’s features, such as tracing your rounds, calculating your mileage, and showing you nearby places of interest. We also use this information to improve our app and services, such as analyzing user behavior and preferences, and providing you with personalized offers and recommendations.
      Device information: We collect information about your device, such as your device model, operating system, unique device identifier, IP address, and app version. We use this information to ensure the proper functioning of the app, to troubleshoot issues, and to enhance our app and services.
      Usage information: We collect information about how you use the app, such as the time and duration of your sessions, the features you access, the actions you take, and the feedback you provide. We use this information to understand how you interact with the app, to improve our app and services, and to measure our app’s performance and effectiveness.
    </section>
    <section class="flex flex-col gap-2">
      <h2 class="text-primary font-bold text-xl">How do we use your information?</h2>
      We use your information for the following purposes:
      <ul class="list-disc pl-10 flex flex-col gap-3">
        <li>To provide you with the app’s features and functionality</li>
        <li>To improve our app and services based on your feedback and usage</li>
        <li>To personalize your app experience based on your location and preferences</li>
        <li>To communicate with you about the app and our services, such as sending you notifications, updates, newsletters, surveys, and promotions</li>
        <li>To protect our app and users from fraud, abuse, and unauthorized access</li>
        <li>To comply with our legal obligations and enforce our terms of service</li>
      </ul>
    </section>
    <section class="flex flex-col gap-2">
      <h2 class="text-primary font-bold text-xl">How do we share your information?</h2>
      We do not sell or rent your personal information to third parties. We may share your information with the following parties for the purposes described above:
      Service providers: We may share your information with third-party service providers who help us operate and maintain the app and our services. These service providers may include cloud hosting providers, analytics providers, marketing providers, payment processors, and customer support providers. We require these service providers to protect your information and use it only for the purposes for which we disclose it to them.
      Affiliates: We may share your information with our affiliates, which are entities that are under common control or ownership with us. We will only share your information with our affiliates for the purposes described in this privacy policy.
      Business partners: We may share your information with third-party business partners who offer products or services that may be of interest to you. These business partners may use your information to send you marketing communications or advertisements that are relevant to your location and preferences. You can opt out of receiving these communications at any time by following the instructions in each message or contacting us at info@oneorder.net
      Law enforcement and legal requests: We may share your information with law enforcement authorities or other parties if we believe that doing so is necessary or appropriate to comply with a legal obligation, respond to a subpoena, warrant, court order, or other legal process, protect our rights or property, prevent or investigate illegal or fraudulent activity, or protect the safety or security of our users or the public.
    </section>
    <section class="flex flex-col gap-2">
      <h2 class="text-primary font-bold text-xl">How do we protect your information?</h2>
      We take reasonable measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. These measures include using encryption, firewalls.
    </section>
  </div>
</template>
<script>
export default {
  name: "DriverPolicy"
}
</script>

<style scoped>

</style>
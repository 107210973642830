<template>
  <section
    class="scroll-area pb-10 sm:py-10 bg-[#F8F7F4] flex justify-center items-center"
  >
    <div class="!w-4/5 flex flex-col gap-4 sm:items-center sm:gap-16 lg:gap-24 lg:flex-row lg:justify-center">
      <div class="flex flex-col gap-4 lg:w-[40%]">
        <h1
          class="text-primary uppercase font-bold text-2xl sm:text-4xl lg:text-3xl"
        >
          {{ $t("whoWeAre.title") }}
        </h1>
        <p class="font-bold text-lg sm:text-2xl lg:text-xl">
          {{ $t("whoWeAre.subtitle") }}
        </p>
        <p class="text-sm sm:text-lg lg:text-xl">
          {{ $t("whoWeAre.description") }}
        </p>
        <div
          class="w-7/12 z-10 sm:text-2xl lg:text-base sm:w-2/5"
          @click="goToSignUp"
        >
          <primary-button :text="$t('whoWeAre.getStarted').toString()" />
        </div>
      </div>
      <img
        src="../assets/images/warehouse.webp"
        alt="Warehouse Image"
        class="mt-8 sm:mt-0 sm:w-[75%] lg:w-[45%] z-20"
      />
    </div>
  </section>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "WhoWeAreSection",
  components: {
    PrimaryButton,
  },
  methods: {
    goToSignUp() {
      window.location.href = `https://app.oneorder.net${
        this.$i18n.locale === "ar" ? "/ar" : ""
      }/signup`;
    },
  },
};
</script>

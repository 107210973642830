import Vue from "vue";
import { getLocaleConfig } from "@/utils/locale";
import VueRouter from "vue-router";
import routes from "@/routes";
import i18n from "@/i18n";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  // get the locale code from the route or store or fallback
  const localeCode =
    to.params.locale ||
    store.getters["locale/getLocale"] ||
    i18n.fallbackLocale;
  // get the locale config object
  const locale = getLocaleConfig(localeCode);
  // set the i18n locale
  i18n.locale = locale.code;
  // set the locale in the router
  next();
});

export default router;

import { SUPPORTED_LOCALES } from "@/constants/locale";

export function changeLocale({ commit }, payload) {
  // Check if the locale is supported
  if (!SUPPORTED_LOCALES.map((locale) => locale.code).includes(payload)) {
    // If the locale is not supported, throw an error
    throw new Error("Locale not supported");
  }
  commit("setLocale", payload);
}

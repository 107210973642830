<template>
  <section
    class="scroll-area w-full overflow-x-hidden py-10 bg-[#F8F7F4] flex justify-center items-center"
  >
    <div class="!w-4/5 flex flex-col gap-4 justify-center  sm:gap-6">
    <h1
      class="text-primary uppercase font-bold text-2xl text-center sm:text-4xl lg:text-3xl z-20"
    >
      {{ $t("itemsPreview.title") }}
    </h1>
    <p
      class="text-center font-bold text-lg sm:font-semibold sm:text-2xl lg:text-xl"
    >
      {{ $t("itemsPreview.subtitle") }}
    </p>
    <p class="text-center text-sm sm:text-2xl lg:text-xl">
      {{ $t("itemsPreview.description") }}
    </p>
    <vue-slick-carousel
      :infinite="true"
      :slides-to-show="isPhone ? 1 : isTablet ? 2 : 3"
      :autoplay="true"
      :speed="2000"
      :arrows="true"
      :draggable="false"
      class="!mt-2"
    >
      <div
        v-for="image in itemPreviewImages"
        :key="image.id"
        class="!flex !flex-col !justify-center !items-center !gap-4 !cursor-pointer"
        @click="goToLink(image.link)"
      >
        <div class="!flex !justify-center !items-center">
          <img
            :src="imagesDirectory + image.src"
            :alt="image.title"
            class="!w-3/4"
          />
        </div>
        <p class="!text-center !font-bold !font-lg !text-secondary">
          {{ image.title }}
        </p>
      </div>
      <template #prevArrow>
        <img
          src="../assets/images/arrowLeft.svg"
          alt="Arrow Left"
          class="!w-8 sm:!w-28 !cursor-pointer !z-20 !left-0 !top-[44%] sm:!-left-10 rtl:!left-auto rtl:!right-0 rtl:md:!right-[-45px] rtl:lg:!right-[-35px] rtl:!rotate-180 rtl:!top-[35%]"
        />
      </template>
      <template #nextArrow>
        <img
          src="../assets/images/arrowLeft.svg"
          alt="Arrow Right"
          class="!w-8 sm:!w-28 !cursor-pointer !z-20 !right-0 !top-[39%] sm:!-right-10 !rotate-180 rtl:!rotate-0 rtl:!left-0 rtl:!top-[35%] rtl:sm:!left-[-45px]"
        />
      </template>
    </vue-slick-carousel>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ItemsPreviewSection",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      imagesDirectory: "/assets/images/itemPreview/",
      isPhone: false,
      isTablet: false,
    };
  },
  computed: {
    itemPreviewImages() {
      return [
        {
          id: 1,
          src: "item_1.webp",
          title: this.$t("categories.cannedAndDry"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/canned-dry`,
        },
        {
          id: 2,
          src: "item_2.webp",
          title: this.$t("categories.beverages"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/beverages`,
        },
        {
          id: 3,
          src: "item_3.webp",
          title: this.$t("categories.bakery"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/bakery`,
        },
        {
          id: 4,
          src: "item_4.webp",
          title: this.$t("categories.dairyAndEggs"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/dairy-eggs`,
        },
        {
          id: 5,
          src: "item_5.webp",
          title: this.$t("categories.paperAndDisposables"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/paper-disposables`,
        },
        {
          id: 6,
          src: "item_6.webp",
          title: this.$t("categories.produce"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/produce`,
        },
        {
          id: 7,
          src: "item_7.webp",
          title: this.$t("categories.meats"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/meats`,
        },
        {
          id: 8,
          src: "item_8.webp",
          title: this.$t("categories.chemicalAndJanitorial"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/chemical-janitorial`,
        },
        {
          id: 9,
          src: "item_9.webp",
          title: this.$t("categories.equipmentAndSmallwares"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/equipment-smallware`,
        },
        {
          id: 10,
          src: "item_10.webp",
          title: this.$t("categories.poultry"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/poultry`,
        },
        {
          id: 11,
          src: "item_11.webp",
          title: this.$t("categories.seafood"),
          link: `https://app.oneorder.net${
            this.$i18n.locale === "ar" ? "/ar" : ""
          }/catalogue/seafood`,
        },
      ];
    },
  },
  created() {
    const width = window.innerWidth;
    if (width <= 640) {
      this.isPhone = true;
    }
    if (width > 640 && width <= 1024) {
      this.isTablet = true;
    }
  },
  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

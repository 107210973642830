<template>
  <section
    class="scroll-area pb-0 lg:pb-10 py-10 bg-[#F8F7F4] flex justify-center items-center"
  >
    <div class="!w-4/5 flex flex-col gap-4 lg:flex-row lg:gap-24 lg:justify-center  lg:items-center">
      <div
        class="flex flex-col justify-center items-start gap-4 lg:w-2/5 lg:order-2 z-20"
      >
        <h1
          class="text-primary font-bold uppercase text-2xl sm:text-4xl lg:text-3xl"
        >
          {{ $t("whatWeDo.title") }}
        </h1>
        <p class="font-bold text-lg sm:text-2xl lg:text-xl">
          {{ $t("whatWeDo.subtitle") }}
        </p>
        <p class="text-lg z-10 sm:text-lg">
          {{ $t("whatWeDo.description") }}
        </p>
        <div
          class="w-1/2 z-10 sm:w-1/3 sm:text-2xl lg:text-base lg:w-1/3"
          @click="goToSignUp"
        >
          <primary-button :text="$t('whatWeDo.joinNow').toString()" />
        </div>
      </div>
      <img
        src="../assets/images/chef_categories.webp"
        alt="App screens"
        class="sm:h-[60vh] pt-10 lg:pt-0  sm:object-contain lg:h-[60vh] z-20"
      />
    </div>
  </section>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "WhatWeDoSection",
  components: {
    PrimaryButton,
  },
  methods: {
    goToSignUp() {
      window.location.href = `https://app.oneorder.net${
        this.$i18n.locale === "ar" ? "/ar" : ""
      }/signup`;
    },
  },
};
</script>

export default {
  common: {
    oneorder: "وان أوردر",
  },
  hero: {
    watchVideo: "مشاهدة الفيديو",
    allSupplies: "احصل علي كل مستلزمات مطعمك في طلب واحد من",
    location: "مصر",
    UAE: "الإمارات العربية المتحدة",
    body: "نحن نؤمن بالطعام الطازج و الأفكار الجديدة كأساس للغذاء و الخدمة.",
    alreadyAMember: "عضو بالفعل؟",
    signIn: "تسجيل الدخول",
    signUp: "الاشتراك",
    videoError: "متصفحك لا يدعم تشغيل الفيديو.",
  },
  whatWeDo: {
    title: "ما نقوم به",
    subtitle:
      "مورد وموزع جملة بتكنولوجيا عالية، متخصص في خدمة قطاع الفنادق و المطاعم و الضيافة (HORECA)",
    description: 'تقدم "وان أوردر" لصناعة الأغذية والمشروبات توريدًا موثوقًا وفي الوقت المناسب للبضائع ذات الجودة مع التمويل المدمج، بسعر ثابت وبدون ضغط إدارة موردين متعددين يوميًا، وكل ذلك من خلال تطبيق واحد سهل الاستخدام.',
    joinNow: "انضم الآن",
  },
  whoWeAre: {
    title: "من نحن",
    subtitle:
      'في "وان أوردر"، نحن نتفهم التحديات التي تواجهها في الحصول على الإمدادات التي تحتاجها في الوقت المناسب. نحن شريكك التجاري الموثوق الذي يغطي جميع احتياجاتك في مجال المشتريات.',
    description:
      'نحن متجركم "الوجهة الواحدة" للحصول على أفضل الأسعار لأفضل جودة من الإمدادات من خلال عملية طلب رقمية مبسطة. يضمن شبكتنا الواسعة من البائعين ومجموعة كبيرة من منتجاتنا عالية الجودة أن جميع احتياجاتك يتم تلبيتها من البداية إلى النهاية.',
    getStarted: "ابدأ الآن",
  },
  appPreview: {
    title: "أداة طلب بسيطة وسريعة",
    subtitle: "استخدم تطبيقنا المحمول لإدارة طلباتك",
    images: {
      home: {
        title: "استكشف",
        description:
          "أحدث المجموعات والتحديثات في لمحة.",
      },
      myList: {
        title: "طلب مبسط",
        description: "قوائم طلبات متوافقة مع أحتياجاتك.",
      },
      reports: {
        title: "تحليلات عميقة",
        description: "تقارير شاملة لاتخاذ قرارات ذكية.",
      },
      reportsDetailed: {
        title: "مؤشرات الشراء",
        description: "تصفح تاريخ و أنماط طلباتك."
      },
      billing: {
        title: "فواتير بدون عناء",
        description: "عملية فواتير شفافة وميسّرة."
      }
    },
  },
  itemsPreview: {
    title: "طازج من الجذور",
    subtitle:
      'الطعام الطازج والأفكار الجديدة هي أساس الطعام والخدمة. تقدم"وان أوردر" منتجات استثنائية ، وقطع لحم مخصصة ، ومأكولات بحرية عالية الجودة ، وأطعمة مستوردة تقدم مجموعة من النكهات العالمية.',
    description:
      'شبكتنا الواسعة من الموردين المتخصصين تتوافق جيدًا مع خبرتنا في الطهي والأعمال. تربط مجموعة "وان أوردر" التي لا مثيل لها من المكونات المبتكرة والمنتجات فائقة الحداثة عملك بشبكة التوزيع الرائدة في الصناعة ، مما يبقيك مزودًا بأحدث المنتجات والاتجاهات والأفكار في مكان واحد.',
  },
  categories: {
    meats: "اللحوم",
    seafood: "الاسماك",
    poultry: "الدواجن",
    dairyAndEggs: "بيض وألبان",
    produce: "فاكهة وخضروات",
    cannedAndDry: "المنتجات المعلبة والجافة",
    bakery: "المخبوزات",
    beverages: "المشروبات",
    paperAndDisposables: "المنتجات الورقية والمستهلكات",
    chemicalAndJanitorial: "الكيماويات والمنظفات",
    equipmentAndSmallwares: "المعدات والأدوات الصغيرة",
  },
  whyOneOrder: {
    title: "لماذا وان أوردر؟",
    reasons: {
      one: {
        title: "الطلب عبر الإنترنت مع لوحة معلومات مخصصة",
      },
      two: {
        title: "وفورات التكلفة المتزايدة",
      },
      three: {
        title: "العناصر التعريفية المخصصة",
      },
      four: {
        title: "إدارة سلسلة التوريد الشاملة",
      },
      five: {
        title: "الجودة المتسقة والموثوقة",
      },
      six: {
        title: "يزيل الحاجة إلى عمليات المستودعات المعقدة",
      },
      seven: {
        title: "تسهيلات رأس المال العامل لتعزيز التدفقات النقدية",
      },
      eight: {
        title: "التسليم في اليوم التالي",
      },
    },
  },
  information: {
    moreThan: "أكثر من",
    suppliers: "١٥٠ مورد",
    clients: "١٠٠٠ عميل",
  },
  press: {
    title: {
      one: "كيف يحسن ",
      two: " كفاءات سلسلة التوريد في HORECA",
    },
  },
  howItWorks: {
    title: "كيف يعمل؟",
    steps: {
      one: {
        title: "قم بتنزيل التطبيق وأكمل عملية التسجيل.",
      },
      two: {
        title: "فريق الترحيب لدينا سيتواصل معك لتنشيط حسابك.",
      },
      three: {
        title: "ابدأ رحلة المشتريات عبر الإنترنت الخاصة بك مع",
      },
    },
    becomeASupplier: "كن موردا",
    becomeACustomer: "كن عميلًا",
  },
  navigation: {
    whatWeDo: "ما نقوم به",
    whoWeAre: "من نحن",
    sneakPeak: "لمحة سريعة",
    products: "منتجات",
    whyOneorder: "لماذا وان أوردر؟",
    partners: "شركاء",
    press: "إعلام",
    howItWorks: "كيف يعمل؟",
    contactUs: "تواصل معنا",
    signIn: "تسجيل الدخول",
    joinNow: "الاشتراك",
  },
  footer: {
    copyRight: "© جميع الحقوق محفوظة لشركة وان أوردر، ٢٠٢٢.",
  },
};

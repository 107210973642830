<template>
  <div :style="{ fontFamily: fontUsed }">
    <navigation-bar
      :is-menu-open="isMenuOpen"
      @toggleMenu="isMenuOpen = !isMenuOpen"
      @goToSection="handleGoToSection"
    />
    <div class="scroll-container flex flex-col lg:block">
      <hero-section ref="HeroSection" :is-menu-open="isMenuOpen"  />
      <what-we-do-section ref="whatWeDoSection" />
      <who-we-are-section ref="WhoWeAreSection" />
      <app-preview-section ref="AppPreviewSection" />
      <items-preview-section ref="ItemsPreviewSection" />
      <why-one-order-section ref="WhyOneOrderSection" />
      <information-section ref="InformationSection" />
      <press-section ref="PressSection" />
      <how-it-works-section ref="HowItWorksSection" />
    </div>
  </div>
</template>

<script>
import HeroSection from "../components/Hero.vue";
import WhatWeDoSection from "../components/WhatWeDo.vue";
import WhoWeAreSection from "../components/WhoWeAre.vue";
import AppPreviewSection from "../components/AppPreview.vue";
import ItemsPreviewSection from "../components/ItemsPreview.vue";
import InformationSection from "../components/Information.vue";
import NavigationBar from "../components/NavigationBar.vue";
import WhyOneOrderSection from "../components/WhyOneOrder.vue";
import HowItWorksSection from "../components/HowItWorks.vue";
import PressSection from "../components/Press.vue";

export default {
  name: "HomePage",
  components: {
    HeroSection,
    NavigationBar,
    WhatWeDoSection,
    WhoWeAreSection,
    AppPreviewSection,
    ItemsPreviewSection,
    WhyOneOrderSection,
    InformationSection,
    HowItWorksSection,
    PressSection,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    fontUsed() {
      return this.$i18n.locale === "ar"
        ? "'Tajawal', sans-serif"
        : "'Montserrat', sans-serif";
    },
  },

  watch: {
    isMenuOpen() {
      if (this.isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },

  methods: {
    handleGoToSection(section) {
      this.isMenuOpen = false;

      this.$nextTick(() => {
        const el = this.$refs[section];

        if (el) {
          el.$el.scrollIntoView();
        }
      });
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* .scroll-container,
.scroll-area {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overscroll-behavior: none;
} */

/* @media (min-width: 768px) { */
/* .scroll-container {
  overflow: auto;
  scroll-snap-type: y proximity;
}
.scroll-area {
  scroll-snap-align: start;
  scroll-snap-stop: always;
} */
/* } */

.scroll-container,
.scroll-area {
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .scroll-container,
  .scroll-area {
    width: 100%;
    height: 100svh;
    height: 100vh;
  }
  .scroll-container {
    overflow: auto;
    scroll-snap-type: y mandatory;
  }
  .scroll-area {
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
  .scroll-container,
  .scroll-area {
    margin: 0 auto;
  }
}
</style>

export default {
  common: {
    oneorder: "One Order",
  },
  hero: {
    watchVideo: "Watch Video",
    allSupplies: "get all your hotel & restaurant supplies in just",
    location: "Egypt",
    UAE: "United Arab Emirates",
    body: "We believe fresh food and fresh ideas are at the heart of food and service.",
    alreadyAMember: "Already a member?",
    signUp: "Sign Up",
    signIn: "Sign In",
    videoError: "Your browser does not support the video tag.",
  },
  whatWeDo: {
    title: "What We Do",
    subtitle:
      "A technology enabled supplier and wholesale distributor catering to HORECA",
    description:
      "OneOrder offers the F&B industry a reliable, timely supply of quality goods with embedded financing, at a consistent price and without the stress of managing various suppliers on a daily basis, all through a single user-friendly application.",
    joinNow: "Join Now",
  },
  whoWeAre: {
    title: "Who We Are",
    subtitle:
      "At One Order, we understand the challenges of sourcing and procuring your much needed supplies on time. We are your reliable business partner covering all your procurement needs.",
    description:
      "We are your ONE STOP SHOP for sourcing top quality supplies at the best prices through a streamlined digital ordering process. Our vast network of vendors and large array of top-quality products ensures that all your needs are covered from start to finish.",
    getStarted: "Get Started",
  },
  appPreview: {
    title: "Simple & fast ordering tool",
    subtitle: "Manage your Orders through our mobile app",
    images: {
      home: {
        title: "Discover and Explore",
        description:
          "Latest collections and updates at a glance.",
      },
      myList: {
        title: "Streamlined Ordering",
        description:
          "Customizable, industry-aligned order lists.",
      },
      reports: {
        title: "Insightful Analytics",
        description:
          "Comprehensive reports for smart decision-making.",
      },
      reportsDetailed: {
        title: "Purchase Trends",
        description: "Visualize your order history and patterns."
      },
      billing: {
        title: "Hassle-Free Billing",
        description: "Transparent, streamlined billing process."
      }
    },
  },
  itemsPreview: {
    title: "Fresh from the roots",
    subtitle:
      "Fresh food and fresh ideas are at the heart of food and service. One Order delivers exceptional produce, custom cuts of meat, high-quality seafood, and imported foods offering an array of global flavors.",
    description:
      "Our extensive network of specialty suppliers' pairs well with our culinary and business expertise. One Order's unparalleled selection of innovative ingredients and cutting- edge products connects your business to the industry-leading distribution network - keeping you stocked with the freshest products, trends, and ideas all in one place.",
  },
  categories: {
    meats: "Meats",
    seafood: "Seafood",
    poultry: "Poultry",
    dairyAndEggs: "Dairy & Eggs",
    produce: "Produce",
    cannedAndDry: "Canned & Dry",
    bakery: "Bakery",
    beverages: "Beverages",
    paperAndDisposables: "Paper & Disposables",
    chemicalAndJanitorial: "Chemical & Janitorial",
    equipmentAndSmallwares: "Equipment & Smallwares",
  },
  whyOneOrder: {
    title: "Why Oneorder",
    reasons: {
      one: {
        title: "Online Ordering With A Personalized Dashboard",
      },
      two: {
        title: "Increased Cost Savings",
      },
      three: {
        title: "Personalized, Tailored SKUs",
      },
      four: {
        title: "All-in-One Supply Chain Management",
      },
      five: {
        title: "Consistent, Reliable Quality",
      },
      six: {
        title: "Removes Need for Complex Warehouse Operations",
      },
      seven: {
        title: "Working Capital Facilities to Boost Cash Flows",
      },
      eight: {
        title: "Next Day Delivery",
      },
    },
  },
  information: {
    moreThan: "More than",
    suppliers: "150 suppliers",
    clients: "1000 clients",
  },
  press: {
    title: {
      one: "read about how ",
      two: "is improving HORECA supply chain inefficiencies",
    },
  },
  howItWorks: {
    title: "How It Works?",
    steps: {
      one: {
        title: "Download the app and complete the signup process.",
      },
      two: {
        title:
          "Our on boarding team will reach out to you to activate your account.",
      },
      three: {
        title: "Start your online procurement journey with",
      },
    },
    becomeASupplier: "Become a Supplier",
    becomeACustomer: "Become a Customer",
  },
  navigation: {
    whatWeDo: "What We Do",
    whoWeAre: "Who We Are",
    sneakPeak: "Sneak Peak",
    products: "Products",
    whyOneorder: "Why ONEORDER",
    partners: "Partners",
    press: "Press",
    howItWorks: "How It Works",
    contactUs: "Contact Us",
    signIn: "Sign In",
    joinNow: "Join Now",
  },
  footer: {
    copyRight: "© ONEORDER, 2022. All rights reserved.",
  },
};

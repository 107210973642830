<template>
  <section
    class="scroll-area w-screen flex flex-col gap-16 justify-center overflow-x-hidden lg:gap-24 py-10"
  >
    <div class="flex flex-col gap-6 justify-center sm:gap-12">
      <h2
        class="uppercase text-secondary font-bold text-lg text-center sm:text-3xl"
      >
        {{ $t("information.moreThan") }}
        <span class="text-primary">{{ $t("information.suppliers") }}</span>
      </h2>

      <vue-slick-carousel v-bind="settings">
        <div
          v-for="image in suppliersImages"
          :key="image.id"
          class="!flex flex-col justify-center items-center gap-4"
        >
          <div class="flex justify-center items-center">
            <img
              :src="supplierImagesDirectory + image.src"
              :alt="image.alt"
              class="w-3/4 -z-20 max-h-48"
            />
          </div>
        </div>
      </vue-slick-carousel>
    </div>
    <div class="flex flex-col gap-6 justify-center sm:gap-12">
      <h2
        class="uppercase text-secondary font-bold text-lg text-center sm:text-3xl"
      >
        {{ $t("information.moreThan") }}
        <span class="text-primary">{{ $t("information.clients") }}</span>
      </h2>

      <vue-slick-carousel v-bind="settings" :rtl="true">
        <div
          v-for="image in clientImages"
          :key="image.id"
          class="!flex flex-col justify-center items-center gap-4"
        >
          <div class="flex justify-center items-center">
            <img
              :src="clientsImagesDirectory + image.src"
              :alt="image.alt"
              class="w-3/4 -z-20 max-h-48"
            />
          </div>
        </div>
      </vue-slick-carousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "InformationSection",
  components: { VueSlickCarousel },
  data() {
    return {
      supplierImagesDirectory: "/assets/images/suppliers/laptop/",
      clientsImagesDirectory: "/assets/images/clients/laptop/",
      isPhone: false,
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: this.isPhone ? 3 : 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: false,
        swipe: false,
        touchMove: false,
        draggable: false,
      },
      clientImages: [
        {
          id: 1,
          src: "al_alamein_hotel.webp",
          alt: "Al Alamein Hotel",
        },
        {
          id: 2,
          src: "ayamena.webp",
          alt: "Ayamena",
        },
        {
          id: 3,
          src: "barbar.webp",
          alt: "Barbar",
        },
        {
          id: 4,
          src: "beja.webp",
          alt: "Beja",
        },
        {
          id: 5,
          src: "bistro.webp",
          alt: "Bistro",
        },
        {
          id: 6,
          src: "blaze.webp",
          alt: "Blaze",
        },
        {
          id: 7,
          src: "bosporus.webp",
          alt: "Bosporus",
        },
        {
          id: 8,
          src: "boulevard.webp",
          alt: "Boulevard",
        },
        {
          id: 9,
          src: "buffalo_burger.webp",
          alt: "Buffalo Burger",
        },
        {
          id: 10,
          src: "caracas.webp",
          alt: "Caracas",
        },
        {
          id: 11,
          src: "crave.webp",
          alt: "Crave",
        },
        {
          id: 12,
          src: "crimson.webp",
          alt: "Crimson",
        },
        {
          id: 13,
          src: "crowne_plaza.webp",
          alt: "Crowne Plaza",
        },
        {
          id: 14,
          src: "em_sherif.webp",
          alt: "Em Sherif",
        },
        {
          id: 15,
          src: "fairmont.webp",
          alt: "Fairmont",
        },
        {
          id: 16,
          src: "four_seasons.webp",
          alt: "Four Seasons",
        },
        {
          id: 17,
          src: "fuego.webp",
          alt: "Fuego",
        },
        {
          id: 18,
          src: "hyatt_regency.webp",
          alt: "Hyatt Regency",
        },
        {
          id: 19,
          src: "jw_marriott.webp",
          alt: "JW Marriott",
        },
        {
          id: 20,
          src: "kazoku.webp",
          alt: "Kazoku",
        },
        {
          id: 21,
          src: "le_meridien.webp",
          alt: "Le Meridien",
        },
        {
          id: 22,
          src: "lexies.webp",
          alt: "Lexies",
        },
        {
          id: 23,
          src: "loft.webp",
          alt: "Loft",
        },
        {
          id: 24,
          src: "longhorn.webp",
          alt: "Longhorn",
        },
        {
          id: 25,
          src: "marriott.webp",
          alt: "Marriott",
        },
        {
          id: 26,
          src: "movenpick.webp",
          alt: "Movenpick",
        },
        {
          id: 27,
          src: "nisantasi.webp",
          alt: "Nisantasi",
        },
        {
          id: 28,
          src: "novotel.webp",
          alt: "Novotel",
        },
        {
          id: 29,
          src: "ovio.webp",
          alt: "Ovio",
        },
        {
          id: 30,
          src: "pier_88.webp",
          alt: "Pier 88",
        },
        {
          id: 31,
          src: "rixos.webp",
          alt: "Rixos",
        },
        {
          id: 32,
          src: "royal_maxim_palace_kempinski.webp",
          alt: "Royal Maxim Palace Kempinski",
        },
        {
          id: 33,
          src: "sachi.webp",
          alt: "Sachi",
        },
        {
          id: 34,
          src: "savoy.webp",
          alt: "Savoy",
        },
        {
          id: 35,
          src: "sky_rim.webp",
          alt: "Sky Rim",
        },
        {
          id: 36,
          src: "steigenberger.webp",
          alt: "Steigenberger",
        },
        {
          id: 37,
          src: "stregis.webp",
          alt: "St Regis",
        },
        {
          id: 38,
          src: "the_nile_ritz_carlton_cairo.webp",
          alt: "The Nile Ritz Carlton Cairo",
        },
        {
          id: 39,
          src: "shifa.webp",
          alt: "Shifa Hospital",
        },
        {
          id: 40,
          src: "elfouad.webp",
          alt: "El Fouad",
        },
        {
          id: 41,
          src: "cleopatra_hospitals.webp",
          alt: "Cleopatra Hospitals",
        },
        {
          id: 42,
          src: "as_salam.webp",
          alt: "As Salam",
        },
        {
          id: 43,
          src: "burger_king.webp",
          alt: "Burger King",
        },
      ],
      suppliersImages: [
        {
          id: 1,
          src: "americana.webp",
          alt: "Americana",
        },
        {
          id: 2,
          src: "baresa.webp",
          alt: "Baresa",
        },
        {
          id: 3,
          src: "calgonit.webp",
          alt: "Calgonit",
        },
        {
          id: 4,
          src: "cooks.webp",
          alt: "Cooks",
        },
        {
          id: 5,
          src: "dixie_mills.webp",
          alt: "Dixie Mills",
        },
        {
          id: 6,
          src: "dolphin.webp",
          alt: "Dolphin",
        },
        {
          id: 7,
          src: "elarosa_tea.webp",
          alt: "El Arosa Tea",
        },
        {
          id: 8,
          src: "eldoha.webp",
          alt: "El Doha",
        },
        {
          id: 9,
          src: "elrashedy.webp",
          alt: "El Rashedy",
        },
        {
          id: 10,
          src: "fern.webp",
          alt: "Fern",
        },
        {
          id: 11,
          src: "fine.webp",
          alt: "Fine",
        },
        {
          id: 12,
          src: "five_stars.webp",
          alt: "Five Stars",
        },
        {
          id: 13,
          src: "harvest.webp",
          alt: "Harvest",
        },
        {
          id: 14,
          src: "heinz.webp",
          alt: "Heinz",
        },
        {
          id: 15,
          src: "hellmanns.webp",
          alt: "Hellmanns",
        },
        {
          id: 16,
          src: "imtenan.webp",
          alt: "Imtenan",
        },
        {
          id: 17,
          src: "italiano.webp",
          alt: "Italiano",
        },
        {
          id: 18,
          src: "juhayna.webp",
          alt: "Juhayna",
        },
        {
          id: 19,
          src: "kiri.webp",
          alt: "Kiri",
        },
        {
          id: 20,
          src: "lipton.webp",
          alt: "Lipton",
        },
        {
          id: 21,
          src: "mainz.webp",
          alt: "Mainz",
        },
        {
          id: 22,
          src: "mars.webp",
          alt: "Mars",
        },
        {
          id: 24,
          src: "najjar.webp",
          alt: "Najjar",
        },
        {
          id: 25,
          src: "nescafe.webp",
          alt: "Nescafe",
        },
        {
          id: 26,
          src: "nestle.webp",
          alt: "Nestle",
        },
        {
          id: 27,
          src: "nido.webp",
          alt: "Nido",
        },
        {
          id: 28,
          src: "nutella.webp",
          alt: "Nutella",
        },
        {
          id: 29,
          src: "president.webp",
          alt: "President",
        },
        {
          id: 30,
          src: "red_bull.webp",
          alt: "Red Bull",
        },
        {
          id: 31,
          src: "regina.webp",
          alt: "Regina",
        },
        {
          id: 32,
          src: "roots.webp",
          alt: "Roots",
        },
        {
          id: 33,
          src: "saluti.webp",
          alt: "Saluti",
        },
        {
          id: 34,
          src: "shady_pack.webp",
          alt: "Shady Pack",
        },
        {
          id: 35,
          src: "unilever.webp",
          alt: "Unilever",
        },
        {
          id: 36,
          src: "vitrac.webp",
          alt: "Vitrac",
        },
        {
          id: 37,
          src: "wadi_food.webp",
          alt: "Wadi Food",
        },
        {
          id: 38,
          src: "yamama.webp",
          alt: "Yamama",
        },
      ],
    };
  },
  created() {
    const width = window.innerWidth;
    if (width <= 640) {
      this.isPhone = true;
    }
  },
};
</script>

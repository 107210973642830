<template>
  <section
    class="scroll-area w-4/5 flex flex-col gap-6 items-center justify-center overflow-x-hidden py-10"
  >
    <h1
      class="text-lg text-secondary uppercase font-bold text-center w-4/5 sm:text-3xl"
    >
      {{ $t("press.title.one")
      }}<span class="text-primary">{{ $t("common.oneorder") }} </span
      >{{ $t("press.title.two") }}
    </h1>
    <div
      class="flex flex-col lg:px-20 gap-12 justify-around items-center sm:gap-16 lg:grid lg:grid-cols-3 cursor-pointer lg:gap-6"
    >
      <div
        v-for="paper in press"
        :key="paper.id"
        class="flex flex-col gap-3 justify-center items-center"
        @click="goToLink(paper.link)"
      >
        <img
          :src="pressDirectory + paper.src"
          :alt="paper.alt"
          class="w-4/5 object-contain sm:w-[60%]"
        />
        <img
          :src="pressDirectory + paper.screen_shot"
          :alt="paper.alt"
          class="lg:w-full h-[30rem] object-contain drop-shadow-xl"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PressSection",
  data() {
    return {
      pressDirectory: "/assets/images/press/",
      press: [

        {
          id: 1,
          src: "forbes.webp",
          screen_shot: "forbes_ss_1.webp",
          alt: "Forbes",
          link: "https://www.forbesmiddleeast.com/innovation/startups/oneorder-raises-%2416m-in-a-series-a-funding-round-to-expand-into-gcc-region",
        },

        {
          id: 3,
          src: "wamda.webp",
          screen_shot: "wamda_ss_1.webp",
          alt: "Wamda",
          link: "https://www.wamda.com/2024/05/oneorder-raises-16-million-series-a-propel-gcc-expansion-plans",
        },
        {
          id: 2,
          src: "tech_crunch.webp",
          screen_shot: "techcrunch_ss_1.webp",
          alt: "Tech Crunch",
          link: "https://techcrunch.com/2022/12/05/with-3m-new-funding-egyptian-startup-oneorder-sets-out-on-growth-drive/",
        },
      ],
    };
  },
  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped></style>

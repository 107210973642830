<template>
  <button class="font-bold text-white bg-primary rounded-3xl px-8 py-2 w-full">
    <span class="flex justify-center items-center rtl:mt-0.5">
      <!-- rtl:mt-0.5 is a fix for arabic text -->
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

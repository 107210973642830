<template>
  <nav class="fixed top-0 flex w-screen justify-end h-20 items-center z-[60] transition-transform duration-300 shadow-md sm:shadow-none"
        :class="[{ '-translate-y-full': hidden }, headerClass]">
    <div
      v-if="!isMenuOpen"
      class="z-50 mt-3 mr-8 md:mr-20 lg:mr-32 sm:mt-4 lg:mt-8 rtl:ml-8 rtl:md:ml-24 flex gap-4 md:gap-8 justify-center items-center"
    >
      <LocaleChanger />
      <img
        src="../assets/images/burgerMenu.svg"
        alt="Burger Menu"
        class="cross cursor-pointer h-4 sm:h-6"
        :class="{ 'transform-rtl': isRTL }"
        @click="$emit('toggleMenu')"
      />
    </div>
    <div
      v-else
      class="absolute top-0 h-screen w-screen bg-[#F8F7F4] sm:flex sm:flex-col sm:gap-12 lg:gap-0"
      :class="{ 'background-rtl': isRTL, background: !isRTL }"
    >
      <div class="flex justify-between h-24 items-center">
        <img
          src="../assets/images/logo.svg"
          alt="OneOrder logo"
          class="w-36 ml-10 sm:mt-12 sm:ml-20 sm:w-52 cursor-pointer rtl:mr-10 rtl:sm:mr-20"
          @click="handleGoToSection('HeroSection')"
        />
        <img
          src="../assets/images/cross.svg"
          alt="Burger Menu"
          class="h-4 mr-6 sm:h-6 cursor-pointer sm:mr-20 sm:mt-10 lg:mt-8 cross rtl:ml-8"
          @click="$emit('toggleMenu')"
        />
      </div>
      <div
        class="flex flex-col text-secondary font-bold text-2xl ml-10 gap-6 sm:mt-8 sm:gap-12 sm:ml-20 lg:gap-4 rtl:mr-10 rtl:sm:mr-20"
      >
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('whatWeDoSection')"
        >
          {{ $t("navigation.whatWeDo") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('WhoWeAreSection')"
        >
          {{ $t("navigation.whoWeAre") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('AppPreviewSection')"
        >
          {{ $t("navigation.sneakPeak") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('ItemsPreviewSection')"
        >
          {{ $t("navigation.products") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('WhyOneOrderSection')"
        >
          {{ $t("navigation.whyOneorder") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('InformationSection')"
        >
          {{ $t("navigation.partners") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('PressSection')"
        >
          {{ $t("navigation.press") }}
        </p>
        <p
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          @click="handleGoToSection('HowItWorksSection')"
        >
          {{ $t("navigation.howItWorks") }}
        </p>
        <a
          class="cursor-pointer hover:text-primary sm:text-3xl lg:text-2xl"
          href="mailto:sales@oneorder.net"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("navigation.contactUs") }}
        </a>
        <p
          class="text-primary cursor-pointer hover:text-secondary sm:text-3xl lg:text-2xl"
          @click="goToSignIn"
        >
          {{ $t("navigation.signIn") }}
        </p>
        <p
          class="text-primary cursor-pointer hover:text-secondary sm:text-3xl lg:text-2xl"
          @click="goToSignUp"
        >
          {{ $t("navigation.joinNow") }}
        </p>
      </div>
    </div>
  </nav>
</template>
<script>
import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
  name: "NavigationBar",
  components: { LocaleChanger },
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastScrollTop: 0,
      hidden: false,
      headerClass: ""
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === "ar";
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > this.lastScrollTop) {
        // Scrolling down
        this.hidden = true;
      } else {
        // Scrolling up
        if (scrollTop < 10) {
          // At the very top
          this.hidden = false;
        } else {
          this.hidden = false;
          this.headerClass = "bg-[#F8F7F4] border-[#e8e8e8] border-b sm:border-none";
        }
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
    handleGoToSection(section) {
      // emit goToSection
      this.$emit("goToSection", section);
    },
    goToSignUp() {
      window.location.href = "https://app.oneorder.net/signup";
    },
    goToSignIn() {
      window.location.href = "https://app.oneorder.net/login";
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .background {
    background: linear-gradient(
      to right,
      rgba(248, 247, 244, 100) 30%,
      rgba(248, 247, 244, 0)
    );
  }
  .background-rtl {
    background: linear-gradient(
      to left,
      rgba(248, 247, 244, 100) 30%,
      rgba(248, 247, 244, 0)
    );
  }
}
.cross:hover {
  filter: invert(19%) sepia(90%) saturate(5778%) hue-rotate(350deg)
    brightness(95%) contrast(95%);
}
.transform-rtl {
  transform: rotateY(180deg);
}
</style>

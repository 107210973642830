<template>
  <section
    class="scroll-area flex flex-col gap-8 justify-center bg-[#FFF8F8] items-center sm:gap-16 py-10"
  >
    <div class="flex flex-col justify-center items-center gap-2">
      <h1
        class="uppercase text-primary text-2xl font-bold text-center sm:text-4xl lg:text-3xl"
      >
        {{ $t("whyOneOrder.title") }}
      </h1>
    </div>
    <div class="grid grid-cols-2 gap-y-8 gap-x-16 w-4/5 sm:grid-cols-4">
      <div
        v-for="reason in reasons"
        :key="reason.id"
        class="flex flex-col justify-start items-center gap-2 cursor-pointer"
        @click="LottieClicked(reason.id)"
        @mouseover="lottieMouseOver(reason.id)"
      >
        <lottie-player
          :ref="reason.id"
          :src="reason.lottieIcon"
          background="transparent"
          speed="1"
          class="h-24 sm:h-28"
          :autoplay="false"
        ></lottie-player>
        <p class="text-center text-xs font-semibold lg:text-lg lg:w-[85%]">
          {{ reason.title }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyOneOrderSection",
  computed: {
    reasons() {
      return [
        {
          id: 1,
          title: this.$t("whyOneOrder.reasons.one.title"),
          lottieIcon: "assets/lottie/Shopping.json",
        },
        {
          id: 2,
          title: this.$t("whyOneOrder.reasons.two.title"),
          lottieIcon: "assets/lottie/saving.json",
        },
        {
          id: 3,
          title: this.$t("whyOneOrder.reasons.three.title"),
          lottieIcon: "assets/lottie/sku.json",
        },
        {
          id: 4,
          title: this.$t("whyOneOrder.reasons.four.title"),
          lottieIcon: "assets/lottie/chain.json",
        },
        {
          id: 5,
          title: this.$t("whyOneOrder.reasons.five.title"),
          lottieIcon: "assets/lottie/quality.json",
        },
        {
          id: 6,
          title: this.$t("whyOneOrder.reasons.six.title"),
          lottieIcon: "assets/lottie/Warehouse.json",
        },
        {
          id: 7,
          title: this.$t("whyOneOrder.reasons.seven.title"),
          lottieIcon: "assets/lottie/cash.json",
        },
        {
          id: 8,
          title: this.$t("whyOneOrder.reasons.eight.title"),
          lottieIcon: "assets/lottie/Truck.json",
        },
      ];
    },
  },
  methods: {
    LottieClicked(LottieRef) {
      this.$refs[LottieRef][0].seek(0);
      this.$refs[LottieRef][0].play();
    },
    lottieMouseOver(LottieRef) {
      this.$refs[LottieRef][0].seek(0);
      this.$refs[LottieRef][0].play();
    },
  },
};
</script>

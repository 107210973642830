import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=5e438e50&scoped=true"
import script from "./HowItWorks.vue?vue&type=script&lang=js"
export * from "./HowItWorks.vue?vue&type=script&lang=js"
import style0 from "./HowItWorks.vue?vue&type=style&index=0&id=5e438e50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e438e50",
  null
  
)

export default component.exports
<template>
  <section
    class="scroll-area w-screen flex flex-col justify-center items-center overflow-x-hidden lg:-mt-0 lg:gap-8 bg-[#FFF8F8] lg:justify-between pt-10"
  >
    <h1
      class="text-2xl font-bold text-primary sm:text-4xl lg:text-3xl lg:pt-24 uppercase"
    >
      {{ $t("howItWorks.title") }}
    </h1>
    <div class="lg:flex lg:justify-evenly lg:w-screen">
      <div class="relative w-full right-[8%] lg:right-0 lg:w-auto">
        <h3
          class="text-[#ED1C2459] text-[145px] text-center font-bold sm:text-[266px] lg:text-[145px]"
        >
          01
        </h3>
        <p
          class="font-bold absolute -right-[25%] z-20 inset-y-[38%] w-11/12 text-sm sm:text-3xl sm:w-[140%] sm:-right-[80%] lg:-right-[95%] lg:text-lg lg:w-[150%] rtl:right-[45%]"
        >
          {{ $t("howItWorks.steps.one.title") }}
        </p>
        <div
          class="flex justify-center items-center absolute bottom-0 w-full gap-2 right-[-10%] lg:justify-start lg:gap-4 lg:-bottom-6"
        >
          <img
            :src="`/assets/images/stores/google_play_${$i18n.locale}.webp`"
            alt="Google Play"
            class="w-24 sm:w-44 lg:w-52 cursor-pointer"
            @click="
              goToLink(
                `https://play.google.com/store/apps/details?id=com.oneorder.oneorder&gl=US&pli=1&hl=${$i18n.locale}`
              )
            "
          />
          <img
            :src="`/assets/images/stores/app_store_${$i18n.locale}.webp`"
            alt="App Store"
            class="w-24 sm:w-44 lg:w-52 cursor-pointer"
            @click="
              goToLink(
                `https://apps.apple.com/vn/app/oneorder/id1591496287?l=${$i18n.locale}`
              )
            "
          />
        </div>
      </div>
      <div class="relative w-full right-[8%] lg:right-0 lg:w-auto">
        <h3
          class="text-[#ED1C2459] text-[145px] text-center font-bold sm:text-[266px] lg:text-[145px]"
        >
          02
        </h3>
        <p
          class="font-bold absolute -right-[25%] z-20 inset-y-[38%] w-11/12 text-sm sm:text-3xl sm:w-[140%] sm:-right-[80%] lg:-right-[90%] lg:text-lg lg:w-[150%] rtl:right-[45%]"
        >
          {{ $t("howItWorks.steps.two.title") }}
        </p>
      </div>
      <div class="relative w-full right-[8%] lg:right-0 lg:w-auto">
        <h3
          class="text-[#ED1C2459] text-[145px] text-center font-bold sm:text-[266px] lg:text-[145px]"
        >
          03
        </h3>
        <p
          class="font-bold absolute -right-[25%] z-20 inset-y-[38%] w-11/12 text-sm sm:text-3xl sm:w-[140%] sm:-right-[80%] lg:-right-[90%] lg:text-lg lg:w-[150%] rtl:right-[45%]"
        >
          {{ $t("howItWorks.steps.three.title") }}
          <span class="whitespace-nowrap uppercase"
            >"{{ $t("common.oneorder") }}"</span
          >
        </p>
      </div>
    </div>
    <div class="flex flex-col gap-4 lg:flex-row lg:gap-20 sm:pb-12 lg:pb-0">
      <div
        class="w-64 sm:w-80 lg:w-64 sm:text-xl lg:text-base"
        @click="goToSignUp"
      >
        <primary-button :text="$t('howItWorks.becomeASupplier').toString()" />
      </div>
      <div
        class="w-64 sm:w-80 lg:w-64 sm:text-xl lg:text-base"
        @click="goToSignUp"
      >
        <primary-button :text="$t('howItWorks.becomeACustomer').toString()" />
      </div>
    </div>
    <footer-section />
  </section>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
import FooterSection from "../components/Footer.vue";
export default {
  name: "HowItWorksSection",
  components: {
    PrimaryButton,
    FooterSection,
  },
  methods: {
    goToSignUp() {
      window.location.href = `https://app.oneorder.net${
        this.$i18n.locale === "ar" ? "/ar" : ""
      }/signup`;
    },
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
h3 {
  font-family: "Oswald", sans-serif;
}
</style>

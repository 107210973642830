<template>
  <div id="app" :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style></style>

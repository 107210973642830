import Home from "./pages/Home.vue";
import { SUPPORTED_LOCALES } from "@/constants/locale";
import Policy from "@/pages/Policy.vue";
import DriverPolicy from "@/pages/DriverPolicy.vue";
import Terms from "@/pages/Terms.vue";

// This function returns a regular expression that matches any of the supported locales
function getLocaleRegex() {
  // Declare a variable to store the regular expression pattern
  let reg = "";
  // Iterate over the array of supported locales
  SUPPORTED_LOCALES.forEach((locale, index) => {
    // Append the locale code to the pattern, followed by a pipe character if it is not the last element
    reg = `${reg}${locale.code}${
      index !== SUPPORTED_LOCALES.length - 1 ? "|" : ""
    }`;
  });
  // Return the pattern wrapped in parentheses to create a capturing group
  return `(${reg})`;
}

export default [
  {
    path: `/:locale${getLocaleRegex()}?`,
    component: Home,
  },
  {
    path: "/policy",
    component: Policy,
  },
  {
    path: '/driver/policy',
    component: DriverPolicy
  },
  {
    path: "/terms",
    component: Terms
  },
  {
    path: "*",
    redirect: "/",
  },
];

<template>
  <section
    class="scroll-area w-screen flex flex-col gap-4 justify-center items-center overflow-x-clip sm:gap-6 relative m-0 py-10 bg-[#F8F7F4]"
  >
    <img
      src="assets/images/oneorder_circle.svg"
      alt="Oneorder white logo"
      class="max-w-none absolute w-[260%] sm:w-[140%] sm:top-[-8rem] sm:left-[-10rem] lg:top-[-12rem] lg:w-[90%] lg:left-[0rem] opacity-10 max-h-[80%] sm:max-h-[none]"
    />
    <div class="flex flex-col gap-4 justify-center sm:gap-0 w-4/5 z-20">
      <h1
        class="text-primary uppercase font-bold text-2xl text-center sm:text-3xl z-20"
      >
        {{ $t("appPreview.title") }}
      </h1>
      <p class="text-lg font-bold text-center sm:text-2xl sm:font-medium z-20">
        {{ $t("appPreview.subtitle") }}
      </p>
    </div>
    <div v-if="!isLaptop" class="w-4/5 z-20">
      <vue-slick-carousel
        ref="c1"
        :as-nav-for="c2"
        :slides-to-show="isTablet ? 2 : 1"
        :infinte="true"
        class="!mb-4"
      >
        <div
          v-for="image in appPreviewImages"
          :key="image.id"
          class="!flex !flex-col !justify-center !items-center !gap-4"
        >
          <div class="!flex !justify-center !items-center">
            <img
              :src="imagesDirectory + image.src"
              :alt="image.title"
              class="!w-3/4"
            />
          </div>
        </div>
        <template #prevArrow>
          <img
            src="../assets/images/arrowLeft.svg"
            alt="Arrow Left"
            class="!w-8 sm:!w-28 !cursor-pointer !z-20 !left-0 !top-[52.5%] sm:!-left-7 rtl:!left-auto rtl:!right-0 rtl:rotate-180"
          />
        </template>
        <template #nextArrow>
          <img
            src="../assets/images/arrowLeft.svg"
            alt="Arrow Right"
            class="!w-8 sm:!w-28 !cursor-pointer !z-20 !right-0 !top-[50%] sm:!-right-7 !rotate-180 rtl:!rotate-0 rtl:!top-[52.7%]"
          />
        </template>
      </vue-slick-carousel>
      <vue-slick-carousel
        ref="c2"
        :as-nav-for="c1"
        :focus-on-select="true"
        :slides-to-show="isTablet ? 2 : 1"
        :arrows="false"
        :infinte="true"
        class="z-20"
      >
        <div
          v-for="image in appPreviewImages"
          :key="image.id"
          class="!flex flex-col justify-center items-center"
        >
          <h4 class="text-center font-bold uppercase font-lg text-secondary">
            {{ image.title }}
          </h4>
          <p class="text-center font-sm">{{ image.description }}</p>
        </div>
      </vue-slick-carousel>
    </div>
    <div v-else class="flex gap-8 justify-center w-4/5 z-20">
      <div
        v-for="image in appPreviewImages"
        :key="image.id"
        class="w-[20%] flex flex-col gap-4"
      >
        <img :src="imagesDirectory + image.src" :alt="image.title" />
        <div class="text-center">
          <h4
              class="font-bold text-secondary text-lg uppercase "
              :class="{'h-14': $i18n.locale !== 'ar'}"
          >
            {{ image.title }}
          </h4>
          <p class="text-sm font-medium">{{ image.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "AppPreviewSection",
  components: { VueSlickCarousel },
  data() {
    return {
      c1: undefined,
      c2: undefined,
      isLaptop: false,
      isTablet: false,
      imagesDirectory: "/assets/images/appPreview/",
    };
  },
  computed: {
    appPreviewImages() {
      return [
        {
          id: 1,
          src: `app_${this.$i18n.locale}_1.webp`,
          title: this.$t("appPreview.images.home.title"),
          description: this.$t("appPreview.images.home.description"),
        },
        {
          id: 3,
          src: `app_${this.$i18n.locale}_3.webp`,
          title: this.$t("appPreview.images.myList.title"),
          description: this.$t("appPreview.images.myList.description"),
        },
        {
          id: 4,
          src: `app_${this.$i18n.locale}_4.webp`,
          title: this.$t("appPreview.images.reports.title"),
          description: this.$t("appPreview.images.reports.description"),
        },
        {
          id: 5,
          src: `app_${this.$i18n.locale}_5.webp`,
          title: this.$t("appPreview.images.reportsDetailed.title"),
          description: this.$t("appPreview.images.reportsDetailed.description"),
        },
        {
          id: 6,
          src: `app_${this.$i18n.locale}_6.webp`,
          title: this.$t("appPreview.images.billing.title"),
          description: this.$t("appPreview.images.billing.description"),
        },
      ];
    },
  },
  created() {
    const width = window.innerWidth;
    if (width > 1024) {
      this.isLaptop = true;
    }
    if (width > 640 && width <= 1024) {
      this.isTablet = true;
    }
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
  },
};
</script>
